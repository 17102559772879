import { gql } from "@apollo/client";

export const CHECKOUT_FIELDS = gql`
	fragment CheckoutFields on CheckoutType {
		id
		sales_person_id
		customer {
			customer_id
			email
			first_name
			last_name
			phone
			total_orders
			contact_via_email
			initial_channel
		}
		pricing {
			delivery
			discount
			subtotal
			total
			tax
			delivery_threshold
		}
		address {
			line_1
			line_2
			city
			province
			post_code
			country
			parcelshop_id
		}
		properties {
			hear_about_us_reason
		}
		discounts {
			current {
				code
				description
				amount
				amount_formatted
				only_apply_to_primary_items
				uses
				type
				type_limit
			}
			future {
				code
				description
				amount
				amount_formatted
				only_apply_to_primary_items
				uses
				type
				type_limit
			}
		}
		products {
			id
			is_trial
			sub_total
			total
			daily_price
			items {
				product_id
				variant_id
				subscription_variant_id
				product_title
				variant_title
				protein
				sku
				image
				weight
				quantity
				needs_scoops
				original_price
				price
				is_treat
			}
			pet {
				name
				gender
				breed
				age
				weight
				size
				activity_level
				life_stage
				fussy
				allergies
				issues
				diet
				species
				goal
				treats_fed
				neutered
				petId
				digestion
				behavioural
				stools
				improve_health
				mobility
				oral
				coat
				working_dog
				subscription {
					scoops_food
					scoops_water
					length
					trial_length
					price_test
					base_size
					selected_plan
				}
			}
		}
		order_2_and_3_discount_test
		is_subscription
		shipping_weight
		discount_code
		source
		referral_token
		delivery_date(format: "Y-m-d")
		earliest_delivery_date
		completed
		completed_at
		created_at
		updated_at
		tests
		stripe_payment_intent_id
		stripe_payment_intent_client_secret
		stripe_setup_intent_id
		stripe_setup_intent_client_secret
		use_terminal
	}
`;

export const CHECKOUT_UPSERT_MUTATION = gql`
	${CHECKOUT_FIELDS}
	mutation UpsertCheckoutMutation(
		$checkout: CheckoutInput
		$id: String
		$validate: Boolean
	) {
		upsertCheckout(id: $id, checkout: $checkout, validate: $validate) {
			...CheckoutFields
		}
	}
`;

export const GET_CHECKOUT_QUERY = gql`
	${CHECKOUT_FIELDS}
	query GetCheckoutQuery($id: String!) {
		getCheckout(id: $id) {
			...CheckoutFields
			__typename
		}
	}
`;

export const VALIDATE_POSTCODE_MUTATION = gql`
	mutation validatePostcodeMutation($post_code: String!) {
		validatePostcode(post_code: $post_code)
	}
`;

export const VALIDATE_EMAIL_MUTATION = gql`
	mutation validateEmailMutation($email: String!) {
		validateEmail(email: $email)
	}
`;

export const VALIDATE_PHONE_MUTATION = gql`
	mutation validatePhoneMutation($phone: String!) {
		validatePhone(phone: $phone)
	}
`;

export const ADD_DISCOUNT_CODE_MUTATION = gql`
	${CHECKOUT_FIELDS}
	mutation AddCodeOrTokenToCheckout($value: String!, $checkout_id: String!) {
		addCodeOrTokenToCheckout(checkout_id: $checkout_id, value: $value) {
			...CheckoutFields
		}
	}
`;
