// TODO type this file
// @ts-nocheck

import { initializeApollo } from "./apolloClient";
import { CREATE_TICKET_QUERY } from "./queries";

export async function createTicket (subject, copy, customer) {
	const data = {
		subject: subject,
		comment: {
			body: copy,
		},
		requester: {
			email: customer.email,
			phone: customer.phone,
			verified: true,
			name: customer.first_name + " " + customer.last_name,
		},
	};
	const apolloClient = initializeApollo();
	
	return await apolloClient
		.mutate({
			mutation: CREATE_TICKET_QUERY,
			variables: {
				data: data,
			},
			context: { clientName: "subApp" },
		})
		.then((result) => {
			return result;
		});
}

export async function createDixaConversation (formData) {
	const formDataTrans = new FormData();
	for (const key in formData) {
		formDataTrans.append(key, formData[key]);
	}

	return await fetch(
		"https://forms.dixa.io/v2/forms/54vtuvPWiDn7h2Dandx8FM/7WEKhXg99f4CkcQfKbdNT4",
		{ method: "POST",
			body: formDataTrans }
	)
		.then((r) => r.json())
		.then((r) => {
			return r;
		})
		.catch((e) => console.log("An error has occurred :(", e));
}
