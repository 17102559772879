export const recipeNames = {
	chicken: "tasty-chicken",
	beef: "scrumptious-beef",
	duck: "delicious-duck",
	turkey: "tempting-turkey",
	herring: "heavenly-herring",
	lamb: "tender-lamb",
	salmon: "super-salmon",
	ailmentTurkey: "sensitive-turkey",
	ailmentBeef: "sensitive-beef",
	ailmentSalmon: "sensitive-salmon",
};

const {
	ailmentBeef,
	ailmentSalmon,
	ailmentTurkey,
	beef,
	chicken,
	duck,
	herring,
	lamb,
	salmon,
	turkey,
} = recipeNames;

const allBaseRecipes = [beef, chicken, duck, herring, lamb, salmon, turkey];
const allAilmentRecipes = [ailmentBeef, ailmentSalmon, ailmentTurkey];
const allRecipes = [...allBaseRecipes, ...allAilmentRecipes];

export const benefits = {
	goodForStools: "Good for stools",
	supportsGutHealth: "Supports gut health",
	goodForSkin: "Good for skin",
	goodForCoats: "Good for coats",
	goodForActiveDogs: "Good for active dogs",
	lowInFat: "Low in fat",
	highInProtein: "High in protein",
	higherInFibre: "Higher in fibre",
	energising: "Energising",
	packedWithVitamins: "Packed with vitamins",
	jointHealth: "Joint health",
	sensitive: "Sensitive",
};

const generateUserSelectionIndex = (recipes: string[], benefit: string) =>
	recipes.map((recipe) => ({ recipe, benefit }));

/*
@Type
recipeBenefitMap : {
  [petRequirement]: {
    [priorityLevel]: {recipe, benefit}[]
  }
}
*/
export const ITERATION_KEYS = [
	"firstPriorityLevel",
	"secondPriorityLevel",
	"thirdPriorityLevel",
	"fourthPriorityLevel",
	"fifthPriorityLevel",
	"sixthPriorityLevel",
] as const;

export const BENEFITS_ALWAYS_SHOW = "always show";

export const recipeBenefitMap: Record<
	string,
	{
		[key in typeof ITERATION_KEYS[number]]?: {
			recipe: string;
			benefit: string;
		}[];
	}
> = {
	[BENEFITS_ALWAYS_SHOW]: {
		[ITERATION_KEYS[0]]: generateUserSelectionIndex(
			allAilmentRecipes,
			benefits.sensitive
		),
	},
	"good for stools": {
		[ITERATION_KEYS[0]]: generateUserSelectionIndex(
			allRecipes,
			benefits.goodForStools
		),
		[ITERATION_KEYS[2]]: generateUserSelectionIndex(
			allRecipes,
			benefits.supportsGutHealth
		),
	},
	"gut friendly": {
		[ITERATION_KEYS[0]]: generateUserSelectionIndex(
			allRecipes,
			benefits.supportsGutHealth
		),
		[ITERATION_KEYS[1]]: generateUserSelectionIndex(
			[chicken, lamb, salmon],
			benefits.higherInFibre
		),
		[ITERATION_KEYS[2]]: generateUserSelectionIndex(
			allRecipes,
			benefits.goodForStools
		),
	},
	"a sensitive stomach": {
		[ITERATION_KEYS[1]]: [
			...generateUserSelectionIndex(allRecipes, benefits.goodForStools),
			...generateUserSelectionIndex(allRecipes, benefits.goodForStools),
		],
		[ITERATION_KEYS[3]]: generateUserSelectionIndex(
			[chicken, beef, duck, turkey, ailmentBeef, ailmentTurkey],
			benefits.packedWithVitamins
		),
	},
	"sensitive skin": {
		[ITERATION_KEYS[1]]: [
			...generateUserSelectionIndex(allRecipes, benefits.goodForSkin),
			...generateUserSelectionIndex(allRecipes, benefits.goodForCoats),
		],
	},
	"good for skin": {
		[ITERATION_KEYS[0]]: generateUserSelectionIndex(
			allRecipes,
			benefits.goodForSkin
		),
		[ITERATION_KEYS[2]]: generateUserSelectionIndex(
			allRecipes,
			benefits.goodForCoats
		),
	},
	"tear stains": {
		[ITERATION_KEYS[0]]: generateUserSelectionIndex(
			allRecipes,
			benefits.goodForCoats
		),
		[ITERATION_KEYS[2]]: generateUserSelectionIndex(
			allRecipes,
			benefits.goodForSkin
		),
	},
	healthy: {
		[ITERATION_KEYS[0]]: [
			...generateUserSelectionIndex(
				[
					chicken,
					beef,
					duck,
					turkey,
					ailmentBeef,
					ailmentSalmon,
					ailmentTurkey,
				],
				benefits.goodForActiveDogs
			),
			...generateUserSelectionIndex(
				[chicken, beef, duck, turkey, ailmentBeef, ailmentTurkey],
				benefits.packedWithVitamins
			),
			...generateUserSelectionIndex(
				[ailmentBeef, ailmentSalmon, ailmentTurkey],
				benefits.lowInFat
			),
			...generateUserSelectionIndex(
				[chicken, beef, duck, turkey],
				benefits.highInProtein
			),
		],
		[ITERATION_KEYS[2]]: generateUserSelectionIndex(
			allRecipes,
			benefits.jointHealth
		),
	},
	pancreatitis: {
		[ITERATION_KEYS[1]]: generateUserSelectionIndex(
			[ailmentBeef, ailmentSalmon, ailmentTurkey],
			benefits.lowInFat
		),
	},
	diabetes: {
		[ITERATION_KEYS[2]]: generateUserSelectionIndex(
			[ailmentBeef, ailmentSalmon, ailmentTurkey],
			benefits.lowInFat
		),
	},
	overweight: {
		[ITERATION_KEYS[3]]: generateUserSelectionIndex(
			[ailmentBeef, ailmentSalmon, ailmentTurkey],
			benefits.lowInFat
		),
	},
	colitis: {
		[ITERATION_KEYS[4]]: generateUserSelectionIndex(
			[ailmentBeef, ailmentSalmon, ailmentTurkey],
			benefits.lowInFat
		),
	},
	utis: {
		[ITERATION_KEYS[5]]: generateUserSelectionIndex(
			[ailmentBeef, ailmentSalmon, ailmentTurkey],
			benefits.lowInFat
		),
	},
	"anal glands": {
		[ITERATION_KEYS[0]]: generateUserSelectionIndex(
			[chicken, lamb, salmon],
			benefits.higherInFibre
		),
	},
	"supports energy": {
		[ITERATION_KEYS[0]]: generateUserSelectionIndex(
			allBaseRecipes,
			benefits.energising
		),
		[ITERATION_KEYS[1]]: generateUserSelectionIndex(
			[chicken, beef, duck, turkey],
			benefits.highInProtein
		),
		[ITERATION_KEYS[2]]: generateUserSelectionIndex(
			[chicken, beef, duck, turkey, ailmentBeef, ailmentTurkey],
			benefits.packedWithVitamins
		),
	},
	"1-2 hours": {
		[ITERATION_KEYS[1]]: generateUserSelectionIndex(
			allBaseRecipes,
			benefits.energising
		),
	},
	"2+ hours": {
		[ITERATION_KEYS[1]]: generateUserSelectionIndex(
			allBaseRecipes,
			benefits.energising
		),
	},
	puppy: {
		[ITERATION_KEYS[2]]: [
			...generateUserSelectionIndex(
				[chicken, beef, duck, turkey],
				benefits.highInProtein
			),
			...generateUserSelectionIndex(allBaseRecipes, benefits.energising),
		],
		[ITERATION_KEYS[4]]: generateUserSelectionIndex(
			[chicken, beef, duck, turkey, ailmentBeef, ailmentTurkey],
			benefits.packedWithVitamins
		),
	},
	quality: {
		[ITERATION_KEYS[3]]: generateUserSelectionIndex(
			[chicken, beef, duck, turkey],
			benefits.highInProtein
		),
	},
	tasty: {
		[ITERATION_KEYS[4]]: generateUserSelectionIndex(
			[chicken, beef, duck, turkey],
			benefits.highInProtein
		),
	},
	"vitamin rich": {
		[ITERATION_KEYS[0]]: generateUserSelectionIndex(
			[chicken, beef, duck, turkey],
			benefits.packedWithVitamins
		),
		[ITERATION_KEYS[2]]: generateUserSelectionIndex(
			[chicken, lamb, salmon],
			benefits.higherInFibre
		),
	},
	senior: {
		[ITERATION_KEYS[0]]: generateUserSelectionIndex(
			allRecipes,
			benefits.jointHealth
		),
	},
	arthritis: {
		[ITERATION_KEYS[1]]: generateUserSelectionIndex(
			allRecipes,
			benefits.jointHealth
		),
	},
};
